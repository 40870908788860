import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ServiceLogo from "../../components/ServiceLogo/ServiceLogo";
import Tag from "../../components/Tag/Tag";
import Umbrella from "../../components/Umbrella/Umbrella";
import UmbrellaContent from "../../components/UmbrellaContent/UmbrellaContent";
import AllComponents from "../content/AllComponents";
import Header from "../layouts/Header";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const ServiceDetailPage = ({ data: { page } }) => (
  <MainLayout
    variant={page.variant}
    header={(
      <Header
        text={<BlockContent blocks={page._rawDescription} />}
        logo={<ServiceLogo variant={page.variant} white />}
        headline={page.title}
        variant="service"
        theme={page.variant.toLowerCase().replace(" ", "")}
        bgVideo={page.variant.toLowerCase().replace(" ", "")}
        umbrella={(
          <div className="grid grid-cols-12 gap-x-30">
            <div className="col-span-12 lg:col-span-8">
              <UmbrellaContent umbrella={<Umbrella label="What we do" color="white" />}>
                {page.tagList?.tags?.map((tag) => (
                  <Tag key={tag.id} white label={tag.label} />
                ))}
              </UmbrellaContent>
            </div>
          </div>
        )}
      />
    )}
  >
    {page.contentElements && (
      <AllComponents elements={page.contentElements} />
    )}
  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    page: sanityServiceDetail(id: {eq: $id}) {
      id
      Metadata {
        ...MetaData
      }
      tagList {
        tags {
          url
          label
        }
        useJumpmarks
      }
      title
      slug {
        current
      }
      variant
      headline
      _rawDescription
      contentElements {
        ... on SanitySectionHeader {
          _key
          _type
          descriptionText
          headline
          color
          sectionLabel
          showCTA
          button {
            variant
            size
            color
            title
            icon
            internalLink {
              ... on SanityServiceDetail {
                id
                slug {
                  current
                }
              }
              ... on SanityServiceOverview {
                id
                slug {
                  current
                }
              }
              ... on SanitySolutionDetail {
                id
                slug {
                  current
                }
              }
              ... on SanitySolutionOverview {
                id
                slug {
                  current
                }
              }
              ... on SanityLegalDocument {
                id
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                name
                detailpage {
                  id
                  title
                  slug {
                    current
                  }
                }
                _type
              }
            }
          }
        }
        ... on SanityServiceCardList {
          _key
          _type
          serviceCardList {
            background
            _rawDescription
            label
            variant
            button {
              variant
              size
              color
              title
              icon
              internalLink {
                ... on SanityLegalDocument {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  detailpage {
                    id
                    title
                    slug {
                      current
                    }
                  }
                  _type
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanitySolutionDetail {
                  id
                  slug {
                    current
                  }
                }
              }
            }
           
          }
        }
        ... on SanitySolutionCardList {
          _key
          _type
          showAs
          cardSize
          imagePosition
          solutionCardList {
            headline
            label
            background
            showButton
            image {
              ...ImageWithPreview
            }
            logo {
              ...ImageWithPreview
            }
            services {
              name
              variant
              lead {
                image {
                  ...ImageWithPreview
                }
                mail
                name
                telephone
                id
              }
            }
            button {
              variant
              size
              color
              title
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanitySolutionDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  detailpage {
                    id
                    title
                    slug {
                      current
                    }
                  }
                  _type
                }
              }
            }
          }
        }
        ... on SanityOfficeCardList {
          _key
          _type
          officeCardList {
            areaTitle
            headline
            _rawAddress
            icon {
              _key
              _type
              _rawAsset
              _rawHotspot
              _rawCrop
            }
            services {
              name
              variant
              lead {
                image {
                  _key
                  _type
                  _rawAsset
                  _rawHotspot
                  _rawCrop
                }
                mail
                name
                telephone
                id
              }
            }
          }
        }
        ... on SanityContentCardList {
          _key
          _type
          variant
          sectionID
          initiallyOpen
          actAsAccordion
          contentCardList {
            headline
            variant
            _rawDescription
            icon
            showRelatedLinks
            showPrimaryCTA
            primaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  detailpage {
                    id
                    title
                    slug {
                      current
                    }
                  }
                  _type
                }
              }
            }
            showSecondaryCTA
            secondaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  detailpage {
                    id
                    title
                    slug {
                      current
                    }
                  }
                  _type
                }
              }
            }
            relatedLinks {
              ... on SanityLegalDocument {
                id
                _type
                slug {
                  current
                }
                title
              }
              ... on SanityServiceDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                name
                detailpage {
                  id
                  title
                  slug {
                    current
                  }
                }
                _type
              }
            }
            overline {
              label
              variant
            }
            _rawDescription
          }
        }
        ... on SanityContentCardGrid {
          _key
          _type
          variant
          sectionID
          contentCards {
            _id
            headline
            variant
            _rawDescription
            icon
            showRelatedLinks
            showPrimaryCTA
            image {
              ...ImageWithPreview
            }
            primaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  detailpage {
                    id
                    title
                    slug {
                      current
                    }
                  }
                  _type
                }
              }
            }
            showSecondaryCTA
            secondaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  title
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  detailpage {
                    id
                    title
                    slug {
                      current
                    }
                  }
                  _type
                }
              }
            }
            relatedLinks {
              ... on SanityLegalDocument {
                id
                _type
                slug {
                  current
                }
                title
              }
              ... on SanityService {
                id
                name
                detailpage {
                  id
                  title
                  slug {
                    current
                  }
                }
                _type
              }
              ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                title
                slug {
                  current
                }
              }
            }
            overline {
              label
              variant
            }
            _rawDescription
          }
        }
        ... on SanityCTACard {
          _key
          _type
          image {
            asset {
              url
            }
          }
          label
          headline
          icon
          _rawDescription
        }
        ... on SanityContentModuleAccordion {
          _key
          _type
          color
          headline
          overline {
            label
            variant
          } 
          _rawDescription
          overlineIcon {
            ...ImageWithPreview
            asset {
              url
            }
          }
          position
          items {
            _type
            label
            _rawText
          }
        }
        ... on SanityContentSection {
          _key
          _type 
          color
          contentSections {
            _type
            headline
            variant
            overline {
              label
              variant
            }
            overlineIcon {
              ...ImageWithPreview
              asset {
                url
              }
            }
            button {
              variant
              size
              color
              title
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  detailpage {
                    id
                    title
                    slug {
                      current
                    }
                  }
                  _type
                }
              }
            }
            _rawDescription
            label
            image {
              ...ImageWithPreview
            }
            position
            showRelatedLinks
            relatedLinks {
              ... on SanityService {
                id
                name
                detailpage {
                  id
                  title
                  slug {
                    current
                  }
                }
                _type
              }
              ... on SanityServiceDetail {
                id
                title
                slug {
                  current
                }
              }
              ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityHomepage {
                id
                slug {
                  current
                }
                title
              }
              ... on SanityLegalDocument {
                id
                title
                slug {
                  current
                }
              }
            }
            umbrellaLabel
            variant
          }
        }
      }
    }
 }
`;

ServiceDetailPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page?.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default ServiceDetailPage;
